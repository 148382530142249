import { createSlice } from '@reduxjs/toolkit';

//spinner1: MystudiosTable open edit studio modal
//spinner2: MystudiosTable open info studio modal
//
//spinner3: delete modal on delete (all table components, & edit profile in settings)
//
//spinner4: StudioserviceTable open edit studioservice modal
//spinner5: StudioserviceTable open info studioservice modal
//
//spinner6: UserTable open edit user modal
//spinner7: EditUserForm open edit user modal
//spinner8: Signin/up(-Form)Modal handling submit
//spinner9: StudioForm handling submit
//spinner10: StudioserviceCategoryForm handling submit for add/edit
//spinner11: StudioServiceForm handling submit
//spinner12: userProfileDetailpage handling update avatar

const spinners = createSlice({
  name: 'spinners',
  initialState: {
    spinners: {
      spinner1: false,
      spinner2: false,
      spinner3: false,
      spinner4: false,
      spinner5: false,
      spinner6: false,
      spinner7: false,
      spinner8: false,
      spinner9: false,
      spinner10: false,
      spinner11: false,
      spinner12: false,
    },
  },
  reducers: {
    updateSpinners: (state, action) => {
      state.spinners = { ...state.spinners, ...action.payload };
    },
  },
});

export const { updateSpinners } = spinners.actions;

export default spinners;
