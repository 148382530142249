import { createSlice } from '@reduxjs/toolkit';
//logged in users data

const activateModal = createSlice({
  name: 'activateModal',
  initialState: {
    activateModal: {
      generalModal: false,
      signin: false,
      signup: false,
      resetpassword: false,
      headerHeroSearchFormLG: false,
    },
  },
  reducers: {
    updateModalstate: (state, action) => {
      state.activateModal = { ...state.activateModal, ...action.payload };
    },
  },
});

export const { updateModalstate } = activateModal.actions;

export default activateModal;
