module.exports = {
  //if user is in USA located, subpath will be /en-US.. if user is located in China, subpath will be /, germany will be /de,
  //turkey will be /tr. if user is not from Usa or GB the english translation includes
  //miles for distance and usa has different time format
  // the /en subpath is for all not featured translations like /switzerland /france /spain and so on
  // needs testing.
  i18n: {
    defaultLocale: 'de',
    locales: ['de', 'en'],
  },
  fallbackLng: {
    default: ['en'],
  },
  nonExplicitSupportedLngs: true,
  // reloadOnPrerender: true,
};
