import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { fetchSelections } from '../services/fetchSelectionsApi';

import searchStudio from './searchStudios';
import searchStudioService from './searchStudioServices';
import searchWithFilters from './searchWithFilters';
import addStudio from './addStudioForm';
import user from './user';
import activateModal from './activateModal';
import spinners from './spinners';
import addOther from './addOtherForm';

const rootReducer = combineReducers({
  searchStudio: searchStudio.reducer,
  searchStudioService: searchStudioService.reducer,
  searchWithFilters: searchWithFilters.reducer,
  addStudio: addStudio.reducer,
  addOther: addOther.reducer,
  spinners: spinners.reducer,
  user: user.reducer,
  activateModal: activateModal.reducer,
  [fetchSelections.reducerPath]: fetchSelections.reducer,
  // any other reducers here
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [],
        ignoredActionPaths: [
          'payload.logoImageFile',
          'payload.backgroundimageFile',
          'meta.baseQueryMeta.request',
          'meta.baseQueryMeta.response',
        ],
        ignoredPaths: [
          'addStudio.checked.logoImageFile',
          'addOther.checked.backgroundimageFile',
          'addOther.checked.logoImageFile',
          'addStudio.checked.backgroundimageFile',
        ],
      },
    }).concat(fetchSelections.middleware),
});
