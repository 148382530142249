import { createSlice } from '@reduxjs/toolkit';
//states for resultpage mapbox component to render resultspoints, but also filter states for filter components
const searchWithFilters = createSlice({
  name: 'searchWithFilters',
  initialState: {
    results: [],
    mapPoints: [],
    //searchQuery is a boundingBox or center coordinates
    bbox: [],
    center: [],
    sortFilters: [
      { name: 'Entfernung' },
      // { name: 'Most Popular' },
      // { name: 'Best Rating' },
      // { name: 'Newest' },
      // { name: 'Price: Low to High' },
      // { name: 'Price: High to Low' },
    ],
    appliedFilters: { sortBy: 'Entfernung' },
  },
  reducers: {
    updateResults: (state, action) => {
      state.results = action.payload;
    },
    updatePoints: (state, action) => {
      state.mapPoints = action.payload;
    },
    updateBBox: (state, action) => {
      state.bbox = action.payload;
      // state.center = [];
    },
    updateCenter: (state, action) => {
      // state.bbox = [];
      state.center = action.payload;
    },
    updateAppliedFilters: (state, action) => {
      state.appliedFilters = { ...state.appliedFilters, ...action.payload };
    },
  },
});

export const { updatePoints, updateResults, updateBBox, updateCenter, updateAppliedFilters } =
  searchWithFilters.actions;

export default searchWithFilters;
