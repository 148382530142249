import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const fetchSelections = createApi({
  reducerPath: 'fetchSelections',
  baseQuery: fetchBaseQuery({
    baseUrl: '/',
  }),
  endpoints: (builder) => ({
    studioServiceSelections: builder.query({
      query: () => '/api/studioServicesSelections',
    }),
  }),
});

export const { useStudioServiceSelectionsQuery } = fetchSelections;
