import { createSlice } from '@reduxjs/toolkit';

const defaultChecked = {
  studioSocials: [],
  studioInformation: [],
  sleepOver: [],
  logoPreview: '',
  logoName: '',
  logoImageFile: '',
  backgroundimagePreview: '',
  backgroundimageName: '',
  backgroundimageFile: '',
  setRules: false,
};
const defaultForm = {
  logo: { publicID: '', resourceType: '', type: '' },
  backgroundimage: { publicID: '', resourceType: '', type: '' },
  studioName: '',
  profileText: '',
  studiotype: 'Heimstudio',
  studioInformation: {},
  studioLanguages: [],
  locationFeatures: [],
  sleepOver: {},
  studioSocials: {
    soundcloud: '',
    spotify: '',
    instagram: '',
    youtube: '',
    facebook: '',
    pinterest: '',
    twitter: '',
    linkedin: '',
    website: '',
  },
  studioRules: [],
  additionalStudioRules: '',
  studioLocation: {
    fullAddress: '',
    address: '',
    city: '',
    state: '',
    country: '',
    postalcode: '',
    geolocation: '',
  },
  user: '',
  status: 1,
};

const addStudio = createSlice({
  name: 'addStudio',
  initialState: {
    form: {
      logo: { publicID: '', resourceType: '', type: '' },
      backgroundimage: { publicID: '', resourceType: '', type: '' },
      studioName: '',
      profileText: '',
      studiotype: 'Heimstudio',
      studioInformation: {},
      studioLanguages: [],
      locationFeatures: [],
      sleepOver: {},
      studioSocials: {
        soundcloud: '',
        spotify: '',
        instagram: '',
        youtube: '',
        facebook: '',
        pinterest: '',
        twitter: '',
        linkedin: '',
        website: '',
      },
      studioRules: [],
      additionalStudioRules: '',
      studioLocation: {
        fullAddress: '',
        address: '',
        city: '',
        state: '',
        country: '',
        postalcode: '',
        geolocation: '',
      },
      user: '',
      status: 1,
    },
    checked: {
      studioInformation: [],
      sleepOver: [],
      logoPreview: '',
      logoName: '',
      logoImageFile: '',
      backgroundimagePreview: '',
      backgroundimageName: '',
      backgroundimageFile: '',
      setRules: false,
    },
  },
  reducers: {
    updateForm: (state, action) => {
      if ('geolocation' in action.payload) {
        state.form.studioLocation = { ...state.form.studioLocation, ...action.payload };
        return;
      }
      state.form = { ...state.form, ...action.payload };
    },
    updateChecked: (state, action) => {
      state.checked = { ...state.checked, ...action.payload };
    },
    resetForm: (state, action) => {
      state.form = { ...defaultForm, user: action.payload ? action.payload : '' };
    },
    resetChecked: (state) => {
      state.checked = defaultChecked;
    },
    handleGeocoderRetrieve: (state, action) => {
      const res = action.payload;
      const geometry = res.features[0];
      const fullAddress = geometry?.properties?.full_address;
      const address = geometry?.properties?.address_line1;
      const city = geometry?.properties?.address_level2;
      const bundesland = geometry?.properties?.address_level1;
      const postcode = geometry?.properties?.postcode;
      const country = geometry?.properties?.country;
      const coordinates = geometry?.geometry?.coordinates;
      state.form.studioLocation = {
        ...state.form.studioLocation,
        fullAddress: fullAddress ? fullAddress : 'nicht vorhanden',
        address: address ? address : `${geometry?.properties?.street}` + ` ${geometry?.properties?.address_number}`,
        city: city ? city : 'nicht vorhanden',
        state: bundesland ? bundesland : city || 'nicht vorhanden',
        country: country ? country : 'nicht vorhanden',
        postalcode: postcode ? postcode : 'nicht vorhanden',
        geolocation: coordinates,
      };
    },
  },
});

export const { updateChecked, updateForm, resetForm, resetChecked, handleGeocoderRetrieve } = addStudio.actions;

export default addStudio;
