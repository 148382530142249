import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

//logged in users data

const user = createSlice({
  name: 'user',
  initialState: {
    user: {
      avatar: { publicID: '', resourceType: '', type: '' },
      username: '',
      firstName: '',
      lastName: '',
      email: '',
      role: '',
      id: '',
      temporaryID: uuidv4(),
      profileText: '',
      slogan: '',
      location: '',
      socials: {
        soundcloud: '',
        spotify: '',
        instagram: '',
        youtube: '',
        facebook: '',
        pinterest: '',
        twitter: '',
        linkedin: '',
        website: '',
      },
      sellermode: 0,
      bookmarked: {
        studioListings: [],
        otherProfiles: [],
        studioServices: [],
        digitalServices: [],
      },
      stripe_customerID: "",
      userProviderSubscription: {
        id: "",
        priceId: "",
        status: "",
        currency: "",
        interval: "",
        intervalCount: "",
        createdAt: "",
        periodStartsAt: "",
        periodEndsAt: "",
        endsAt: "",
      },
    },
    // don't delete: used in [serviceID].js api route
    role: '',
  },
  reducers: {
    updateUser: (state, action) => {
      state.user = action.payload;
    },
    spreadUser: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    updateRole: (state, action) => {
      state.role = action.payload;
    },
  },
});

export const { updateUser, spreadUser, updateRole } = user.actions;

export default user;
