import { signOut, useSession } from 'next-auth/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateRole, updateUser } from '../../slices/user';
import { toast } from 'sonner';
import localization from 'moment/locale/de';
import moment from 'moment';
import { useRouter } from 'next/router';

moment.locale('de');

function getDate() {
  return moment().format('dddd MMM yyyy');
}
function getTime() {
  return moment().format('HH:mm');
}

// is device connected to internet?

// update user if session is outdated or log out
// toast when signed in
export default function UpdateUserLayout({ children }) {
  const { data: session, status } = useSession();
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const router = useRouter();

  //
  useEffect(() => {
    if (status === 'loading' || status === 'unauthenticated') return;
    if ((session && session.token) || !user.id) {
      dispatch(updateUser(session.token));
      dispatch(updateRole(session.token.role));
      return;
    } else if (!session || !session.token) {
      dispatch(updateUser({}));
      dispatch(updateRole(''));
      signOut();
    }
  }, [status, user.id]);

  useEffect(() => {
    if (status === 'loading') return;
    if (session && (!session.token.id || !session.token)) {
      signOut({
        callbackUrl: '/signin',
      });
    }
  }, [status, user.id]);
  //

  // Should render toast?
  useEffect(() => {
    if (!router?.query?.operation) return;
    if (user?.username && router?.query?.operation == 'signin') {
      setTimeout(() => {
        toast.message(`Herzlich Willkommen @${user?.username}`, {
          description: `${getDate()} ${getTime()}`,
          duration: 6000,
        });
        router.replace({
          query: '',
        });
      }, 1000);
    }
  }, [router?.query?.operation]);

  return <section>{children}</section>;
}
