import { createSlice } from '@reduxjs/toolkit';
const languages = [
  'Deutsch',
  'Englisch',
  'Französisch',
  'Schweizerisch',
  'Österreichisch',
  'Chinesisch',
  'Spanisch',
  'Russisch',
  'Hindi',
  'Arabisch',
  'Portugiesisch',
  'Bengalisch',
  'Japanisch',
  'Koreanisch',
  'Türkisch',
  'Italienisch',
  'Thai',
  'Persisch',
  'Polnisch',
  'Ukrainisch',
  'Niederländisch',
  'Serbisch',
  'Nepalesisch',
  'Vietnamesisch',
  'Kurdisch',
  'Ungarisch',
  'Hebräisch',
];
const defaultChecked = {
  socials: [],
  languages: languages,
  logoPreview: '',
  logoName: '',
  logoImageFile: '',
  backgroundimagePreview: '',
  backgroundimageName: '',
  backgroundimageFile: '',
};
const defaultForm = {
  logo: { publicID: '', resourceType: '', type: '' },
  backgroundimage: { publicID: '', resourceType: '', type: '' },
  name: '',
  surname: '',
  languages: [],
  about: '',
  instruments: [],
  genres: [],
  age: '',
  socials: {
    soundcloud: '',
    spotify: '',
    instagram: '',
    youtube: '',
    facebook: '',
    pinterest: '',
    twitter: '',
    linkedin: '',
    website: '',
  },
  location: {
    fullAddress: '',
    address: '',
    city: '',
    state: '',
    country: '',
    postalcode: '',
    geolocation: '',
  },
  user: '',
  status: 1,
};

const addOther = createSlice({
  name: 'addOther',
  initialState: {
    form: {
      role: '',
      name: '',
      surname: '',
      languages: [],
      about: '',
      age: '',
      instruments: [],
      genres: [],
      logo: { publicID: '', resourceType: '', type: '' },
      backgroundimage: { publicID: '', resourceType: '', type: '' },
      socials: {
        soundcloud: '',
        spotify: '',
        instagram: '',
        youtube: '',
        facebook: '',
        pinterest: '',
        twitter: '',
        linkedin: '',
        website: '',
      },
      location: {
        fullAddress: '',
        address: '',
        city: '',
        state: '',
        country: '',
        postalcode: '',
        geolocation: '',
      },
      user: '',
      status: 1,
    },
    checked: {
      logoPreview: '',
      languages: [],
      logoName: '',
      logoImageFile: '',
      backgroundimagePreview: '',
      backgroundimageName: '',
      backgroundimageFile: '',
    },
  },
  reducers: {
    updateForm: (state, action) => {
      if ('geolocation' in action.payload) {
        state.form.location = { ...state.form.location, ...action.payload };
        return;
      }
      state.form = { ...state.form, ...action.payload };
    },
    updateChecked: (state, action) => {
      state.checked = { ...state.checked, ...action.payload };
    },
    updateInstrument: (state, action) => {
      let arr = [];
      action.payload.map((i) => arr.push(i.value));
      state.form.instruments = [...arr];
    },
    updateGenres: (state, action) => {
      let arr = [];
      action.payload.map((i) => arr.push(i.value));
      state.form.genres = [...arr];
    },
    resetLanguages: (state) => {
      state.checked.languages = languages;
    },
    resetForm: (state, action) => {
      state.form = { ...defaultForm, user: action.payload ? action.payload : '' };
    },
    resetChecked: (state) => {
      state.checked = defaultChecked;
    },
    handleGeocoderRetrieve: (state, action) => {
      const res = action.payload;
      const geometry = res.features[0];
      const fullAddress = geometry?.properties?.full_address;
      const address = geometry?.properties?.address_line1;
      const city = geometry?.properties?.address_level2;
      const bundesland = geometry?.properties?.address_level1;
      const postcode = geometry?.properties?.postcode;
      const country = geometry?.properties?.country;
      const coordinates = geometry?.geometry?.coordinates;

      state.form.location = {
        ...state.form.location,
        fullAddress: fullAddress ? fullAddress : 'nicht vorhanden',
        address: address ? address : `${geometry?.properties?.street}` + ` ${geometry?.properties?.address_number}`,
        city: city ? city : 'nicht vorhanden',
        state: bundesland ? bundesland : city || 'nicht vorhanden',
        country: country ? country : 'nicht vorhanden',
        postalcode: postcode ? postcode : 'nicht vorhanden',
        geolocation: coordinates,
      };
    },
  },
});

export const {
  updateChecked,
  updateForm,
  updateInstrument,
  updateGenres,
  resetLanguages,
  resetForm,
  resetChecked,
  handleGeocoderRetrieve,
} = addOther.actions;

export default addOther;
