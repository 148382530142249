import { SessionProvider } from 'next-auth/react';
import { store } from '../slices/index';
import { Provider } from 'react-redux';
import NextNProgress from 'nextjs-progressbar';
import * as gtag from '../lib/gtag';
import Script from 'next/script';
import { ErrorBoundary, HighlightInit } from '@highlight-run/next/client';
import { Toaster } from 'sonner';
import '../styles/globals.css';
import '../styles/custom.css';
import '../components/Wizard/wizard.css';
import '../components/Wizard/bs-stepper.css';
import '../components/DatePicker/styles.css';
import '../components/DatePicker/default.css';
import '../components/TimePicker/styles/react-ios-time-picker.css';
import '../styles/index.css';
import '../styles/index.scss';
import 'rc-slider/assets/index.css';
import UpdateUserLayout from '../components/Layout/updateUserLayout';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { getCookie } from 'cookies-next';
import { appWithTranslation } from 'next-i18next';
import nextI18nextConfig from '../next-i18next.config';

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  const getLayout = Component.getLayout ?? ((page) => page);
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url) => {
      const userId = getCookie('uniqueUserID'); // Getting cookie from client side
      const userAgent = navigator.userAgent;
      if (!!userId && userId !== 'null' && userId !== null) {
        fetch('https://www.esfaras.de/api/logActivity', {
          method: 'POST', // Changed to POST because GET should not include a body
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId,
            path: url,
            userAgent, // Include user agent string containing browser and OS information
          }), // Include only necessary data
        })
          .then((response) => response.json())
          .then((data) => console.log('Activity log response:', data))
          .catch((error) => console.error('Fetching error:', error));
      }
    };

    // Call handleRouteChange initially for the current path
    handleRouteChange('/');

    // Setup listener for future route changes
    router.events.on('routeChangeComplete', handleRouteChange);

    // Clean up the event listener
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      {/* pollyfill for react-currency-input */}
      <Script
        src={
          'https://polyfill.io/v3/polyfill.min.js?features=Intl.NumberFormat%2CIntl.DateTimeFormat%2CIntl.DateTimeFormat.%7EtimeZone.all%2CIntl.DateTimeFormat.%7EtimeZone.golden%2CIntl.DisplayNames%2CIntl.ListFormat%2CIntl.PluralRules%2CIntl.RelativeTimeFormat%2CIntl.getCanonicalLocales%2CIntl.Locale'
        }
      />
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`} />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
            `,
        }}
      />
      {/* trustpilot */}
      <Script
        strategy="lazyOnload" // Load the script lazily after everything else has loaded
        async
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      />
      {/* cookieyes */}
      <Script
        id="cookieyes"
        type="text/javascript"
        src="https://cdn-cookieyes.com/client_data/504fadfaf36b15b3e34fecd7/script.js"
      />
      <HighlightInit
        excludedHostnames={['localhost']}
        projectId={'qe96nwe1'}
        serviceName="esfaras"
        tracingOrigins
        networkRecording={{
          enabled: true,
          recordHeadersAndBody: true,
          urlBlocklist: [],
        }}
      />
      <Provider store={store}>
        <SessionProvider session={session}>
          <ErrorBoundary
            dialogOptions={{
              title: 'Es gab ein Problem.',
              subtitle:
                'Bitte beschreibe uns genau was passiert ist, sodass wir uns schnellstmöglich darum kümmern können.',
              labelComments: 'Kommentar',
              placeholderComments: 'Ich habe etwas getippt und dann einen Button geklickt',
              labelClose: 'Abbrechen',
              labelSubmit: 'Senden',
              hideHighlightBranding: 'true',
              successMessage: 'Erfolgreich gesendet',
            }}>
            <UpdateUserLayout>
              <NextNProgress
                color="rgb(67, 56, 202)"
                startPosition={0.3}
                stopDelayMs={200}
                height={2}
                showOnShallow={true}
              />
              {getLayout(<Component {...pageProps} />)}
              <Toaster position="top-center" expand={false} closeButton visibleToasts={5} />
            </UpdateUserLayout>
          </ErrorBoundary>
        </SessionProvider>
      </Provider>
    </>
  );
}
export default appWithTranslation(MyApp, nextI18nextConfig);
